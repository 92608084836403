import React from "react"
import AnimatedNumber from "animated-number-react"

const animatedCount = props => {
  //const { totalCount } = props
  const dateNow = new Date(Date.now())
  const finalDate = new Date("09/21/2021")
  const diffTime = Math.abs(finalDate - dateNow)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  const formatValue = value => value.toFixed(0)

  return (
    <h2 className="animatedCount" style={{ display: "none" }}>
      <span>
        <span className="number">
          <AnimatedNumber value={diffDays} formatValue={formatValue} delay={1000} className="number" /> Days{" "}
        </span>
        <br />
        21.09.21
      </span>
    </h2>
  )
}
export default animatedCount
