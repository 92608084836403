import React from "react"

export default () => {
  return (
    <footer role="banner">
      <div className="left"></div>
      <div className="right">
        <span className="sponsorsLabel">Proudly sponsored by</span>
        <img src="/Vodafone_logo.svg" width="125px" height="33px" style={{ height: "auto" }} alt="Vodafone" title="Vodafone" />
      </div>
    </footer>
  )
}
